import { AppKit } from '@web3modal/base';
import { EVMWagmiClient } from '@web3modal/base/adapters/evm/wagmi';
import { ConstantsUtil } from '@web3modal/scaffold-utils';
export { authConnector } from '@web3modal/base/adapters/evm/wagmi';
export { defaultWagmiConfig } from '@web3modal/base/adapters/evm/wagmi';
export function createWeb3Modal(options) {
  const wagmiAdapter = new EVMWagmiClient({
    wagmiConfig: options.wagmiConfig,
    siweConfig: options.siweConfig,
    defaultChain: options.defaultChain
  });
  return new AppKit({
    ...options,
    defaultChain: wagmiAdapter.defaultChain,
    adapters: [wagmiAdapter],
    sdkType: 'w3m',
    sdkVersion: `html-wagmi-${ConstantsUtil.VERSION}`
  });
}

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ChainController, RouterController } from '@web3modal/core';
import { customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { ConstantsUtil } from '@web3modal/common';
import { property } from 'lit/decorators.js';
let W3mSwitchActiveChainView = class W3mSwitchActiveChainView extends LitElement {
  constructor() {
    super(...arguments);
    this.unsubscribe = [];
    this.switchToChain = RouterController.state.data?.switchToChain;
    this.navigateTo = RouterController.state.data?.navigateTo;
    this.navigateWithReplace = RouterController.state.data?.navigateWithReplace;
    this.activeChain = ChainController.state.activeChain;
  }
  firstUpdated() {
    this.unsubscribe.push(ChainController.subscribeKey('activeChain', val => this.activeChain = val));
  }
  disconnectedCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
  }
  render() {
    const chainNameString = this.activeChain ? ConstantsUtil.CHAIN_NAME_MAP[this.activeChain] : 'current';
    const switchedChainNameString = this.switchToChain ? ConstantsUtil.CHAIN_NAME_MAP[this.switchToChain] : 'supported';
    return html`
      <wui-flex
        flexDirection="column"
        alignItems="center"
        .padding=${['3xl', 'xl', 'xl', 'xl']}
        gap="xl"
      >
        <wui-flex justifyContent="center" flexDirection="column" alignItems="center" gap="xl">
          <wui-visual name="eth"></wui-visual>
          <wui-text variant="paragraph-500" color="fg-100" align="center">Switch to EVM</wui-text>
          <wui-text variant="small-400" color="fg-200" align="center">
            This feature is not supported on the ${chainNameString} chain. Switch to
            ${switchedChainNameString} chain to proceed using it.
          </wui-text>
          <wui-button size="md" @click=${this.switchActiveChain.bind(this)}>Switch</wui-button>
        </wui-flex>
      </wui-flex>
    `;
  }
  switchActiveChain() {
    if (!this.switchToChain) {
      return;
    }
    ChainController.setActiveChain(this.switchToChain);
    if (this.navigateTo) {
      if (this.navigateWithReplace) {
        RouterController.replace(this.navigateTo);
      } else {
        RouterController.push(this.navigateTo);
      }
    } else {
      RouterController.goBack();
    }
  }
};
W3mSwitchActiveChainView.styles = styles;
__decorate([property()], W3mSwitchActiveChainView.prototype, "activeChain", void 0);
W3mSwitchActiveChainView = __decorate([customElement('w3m-switch-active-chain-view')], W3mSwitchActiveChainView);
export { W3mSwitchActiveChainView };

import { DestroyRef, inject, Injectable } from '@angular/core';
import { FinanceService } from './finance.service';
import { Address, Chain, formatUnits, parseEther } from 'viem';
import {
  Config,
  disconnect,
  getAccount,
  readContract,
  reconnect,
  waitForTransaction,
  watchAccount,
  writeContract,
} from '@wagmi/core';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { setAddress, setBalance } from '../core/store/actions/player.actions';
import { SettingsService } from './settings.service';
import { NotificationsService } from '../core/notifications/notifications.service';
import {
  setMaxWithdrawValue,
  setMinWithdrawValue,
} from '../core/store/actions/settings.actions';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MetaData, WalletStatus, Web3Settings } from '../common/models';
import { ERC20ABI } from '../common/constants/constants';

const USER_EVENTS = [
  'DISCONNECT_SUCCESS',
  'DISCONNECT_ERROR',
  'ERROR_FETCH_TRANSACTIONS',
  'SWITCH_NETWORK',
  'SEND_INITIATED',
  'SEND_SUCCESS',
  'SEND_ERROR',
];

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private destroyRef$ = inject(DestroyRef);
  private modal!: any;
  private config!: Config;
  private depositWalletAddress!: string;
  private walletConnectProjectId!: string;
  private smartContractAddress!: string;
  private currencyId!: string;
  private network!: Chain;
  private metaData!: MetaData;
  private walletIds!: string[];

  private walletStatus$$: BehaviorSubject<WalletStatus> =
    new BehaviorSubject<WalletStatus>('disconnected');
  private isWalletConnecting$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private walletStatus$ = this.walletStatus$$.asObservable();
  private isWalletConnecting$ = this.isWalletConnecting$$.asObservable();

  constructor(
    private readonly financeService: FinanceService,
    private readonly store: Store,
    private readonly settingsService: SettingsService,
    private readonly notificationsService: NotificationsService,
  ) {
    this.initWallet();
    this.getWeb3Settings();
  }

  initWallet() {
    this.getWeb3Settings()
      .pipe(
        tap((data) => {
          this.storeMinMaxValue(data);
          this.setWalletVariables(data);
        }),
        takeUntilDestroyed(this.destroyRef$),
      )
      .subscribe({
        next: () => {
          this.setConfig();
          this.createModal();
          this.reconnectWallet();
          this.watchAccount();
        },
      });
  }

  connectWallet() {
    this.modal.open().then();
  }

  disconnectWallet() {
    this.clearLocalStorage();
    disconnect(this.config);
  }

  clearLocalStorage() {
    localStorage.removeItem('walletState');
    localStorage.removeItem('@w3m/connected_connector');
    localStorage.removeItem('@w3m/recent');
    localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
    localStorage.removeItem('wagmi.recentConnectorId');
    localStorage.removeItem('wagmi.store');
    localStorage.removeItem('wagmi.walletConnect.requestedChains');
  }

  getWalletState(): Observable<WalletStatus> {
    return this.walletStatus$;
  }

  isWalletConnecting(): Observable<boolean> {
    return this.isWalletConnecting$;
  }

  getWalletAddress() {
    const { address } = getAccount(this.config);
    return address;
  }

  private setWalletVariables(data: Web3Settings) {
    this.smartContractAddress = data.fiatCurrencies[0].address;
    this.depositWalletAddress = data.paymentData.walletAddress;
    this.walletConnectProjectId = data.portalSettings.walletConnectProjectId;
    this.currencyId = data.fiatCurrencies[0].id;
    this.network = data.web3Settings;
    this.metaData = {
      ...data.portalSettings.walletMetaData,
      icons: ['../public/favicon.ico'],
    };
    this.walletIds = data.portalSettings.walletIds;
  }

  private setConfig() {
    this.config = defaultWagmiConfig({
      chains: [this.network],
      projectId: this.walletConnectProjectId,
      metadata: this.metaData,
      auth: {
        socials: [],
        email: false,
      },
    });
  }

  private reconnectWallet() {
    if (localStorage.getItem('walletState') === 'connected') {
      this.isWalletConnecting$$.next(true);
      reconnect(this.config)
        .then(() => {
          this.isWalletConnecting$$.next(false);
        })
        .catch(() => this.isWalletConnecting$$.next(false));
    }
  }

  private createModal() {
    this.modal = createWeb3Modal({
      allWallets: 'HIDE',
      allowUnsupportedChain: false,
      wagmiConfig: this.config,
      projectId: this.walletConnectProjectId,
      themeMode: 'dark',
      includeWalletIds: this.walletIds,
    });
  }

  private storeMinMaxValue(data: Web3Settings) {
    this.store.dispatch(
      setMinWithdrawValue({
        minWithdrawValue: data.portalSettings.min_withdraw_value,
      }),
    );
    this.store.dispatch(
      setMaxWithdrawValue({
        maxWithdrawValue: data.portalSettings.max_withdraw_value,
      }),
    );
  }

  private watchAccount() {
    // this.modal.subscribeEvents((state: any) => {
    //   if (USER_EVENTS.includes(state.data.event))
    //     this.notificationsService.openNotification(state.data.event);
    // });

    watchAccount(this.config, {
      onChange: (data) => {
        if (data.status === 'connected') {
          this.updateExternalBalance();
          localStorage.setItem('walletState', 'connected');
        } else if (data.status === 'disconnected') {
          localStorage.removeItem('walletState');
        }
        this.walletStatus$$.next(data.status);
      },
    });
  }

  private async updateExternalBalance() {
    const { address } = getAccount(this.config);
    if (!address) return;
    const balance = await this.getBalance(address);
    this.store.dispatch(setBalance({ balance }));
    this.store.dispatch(setAddress({ address }));
  }

  async sendERC20Transaction(amount: string) {
    const address = this.getWalletAddress();

    if (!address) return;
    const balance = await this.getBalance(address);

    if (this.insufficientFunds(balance, amount)) {
      this.notificationsService.openNotification('INSUFFICIENT_DEPOSIT_FUNDS');
      return;
    }

    try {
      const hash = await writeContract(this.config, {
        abi: ERC20ABI,
        address: this.smartContractAddress as Address,
        functionName: 'transfer',
        args: [this.depositWalletAddress, parseEther(amount)],
      });
      this.notificationsService.openNotification('DEPOSIT_INITIATED');
      const receipt = await waitForTransaction(this.config, {
        hash: hash,
      });
      this.registerDepositing(hash, address, amount);

      if (receipt.status === 'success') {
        this.updateExternalBalance();
        return true;
      } else {
        this.notificationsService.openNotification('DEPOSIT_FAILED');
        return false;
      }
    } catch (e: any) {
      if (e.message.toString().startsWith('User rejected the request')) {
        this.notificationsService.openNotification('DEPOSIT_DENIED');
      }
      return false;
    }
  }

  createWithdrawal(amount: number) {
    const { address } = getAccount(this.config);
    if (!address) return of();
    return this.financeService.createWithdrawal(
      address,
      amount,
      this.currencyId,
    );
  }

  private async getBalance(address: Address) {
    const data = (await readContract(this.config, {
      abi: ERC20ABI,
      address: this.smartContractAddress as Address,
      functionName: 'balanceOf',
      args: [address],
    })) as bigint;
    return formatUnits(data, 18);
  }

  private registerDepositing(
    transactionHash: string,
    from: string,
    amount: string,
  ) {
    this.financeService
      .registerDepositing(transactionHash, from, amount, this.currencyId)
      .subscribe({
        next: () =>
          this.notificationsService.openNotification('DEPOSIT_SUCCESS'),
      });
  }

  private insufficientFunds(str1: string, str2: string): boolean {
    const num1 = parseFloat(str1);
    const num2 = parseFloat(str2);
    return num1 < num2;
  }

  private getWeb3Settings(): Observable<Web3Settings> {
    return this.settingsService.getWeb3Settings();
  }
}
